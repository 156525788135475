import React, { Dispatch, useEffect, useMemo, useState } from 'react';
import { connect } from 'react-redux';
import { IDeliveriesProps } from './IDeliveriesProps';
import { IDeliveryWindow, IStoreState } from '../../types';
import { getScheduledDeliveriesRequest } from '../../actions/Orders/scheduledDeliveriesAction';
import { FullPageLayout } from '../../layouts';
import { EmptyRecord, LoadingThrobber } from '../../components';
import { Button, Dropdown, DropdownOption } from '@orderly/morrisons-component-library';
import { navigateTo } from '../../routing/Navigation';
import { isToday } from '../../helpers/DateHelper';
import { Calendar, DeliveryTruck, InfoCircle, Truck } from 'iconoir-react';
import { formatTime } from '../../helpers/Format';
import FormatDateHelper from '../../helpers/Format/Date/FormatDateHelper';
import styles from './Deliveries.module.scss';

function Deliveries({
  userStores,
  scheduledDeliveries,
  loading,
  getScheduledDeliveries,
}: IDeliveriesProps): JSX.Element {
  const [selectedStoreId, setSelectedStoreId] = useState(-1);
  const { data } = scheduledDeliveries;
  const vaildData = !loading && data.length > 0 && selectedStoreId > 0;

  useEffect(
    () => {
      if (selectedStoreId === -1 && userStores.data != null && userStores.data.stores != null) {
        const store = userStores.data.stores[0];
        if (store !== null) {
          setSelectedStoreId(store.externalId);
        }
      }
    },
    [userStores],
  );

  useEffect(
    () => {
      if (selectedStoreId !== -1) {
        getScheduledDeliveries(selectedStoreId);
      }
    },
    [selectedStoreId],
  );

  const stores: DropdownOption[] = useMemo(
    () => {
      if (userStores != null && userStores.data != null && userStores.data.stores != null) {
        return userStores.data.stores.map((store) => {
          return { value: store.externalId, label: `${store.externalId} - ${store.name}` };
        });
      }
      return [];
    },
    [userStores],
  );

  const trackOrder = (trackingId: string) => {
    navigateTo('https://mysctrackandtrace.dhl.com/order/public/detail?id=' +
      `${trackingId}&indexType=CURRENT&country=GB&activeTab=relatedItems`);
  };

  return (
    <FullPageLayout
      heading="Deliveries"
      breadcrumbs={[
        {
          key: 0,
          text: 'Deliveries',
          url: window.location.pathname,
        },
      ]}
      headerAdditionalContent={
        stores.length > 1 &&
        <Dropdown
          name="Store"
          label=""
          className={styles.storeDropdown}
          placeholder="Select a Store"
          options={stores}
          selectedValue={selectedStoreId}
          onChange={(value: DropdownOption) => { setSelectedStoreId(value.value as number); }}
          error={''}
        />
      }
    >
      {
        vaildData ?
        <div className={styles.container}>
          {
            data.map((deliveryDate) => {
              const { deliveries } = deliveryDate;
              const { date, suffix } = FormatDateHelper.getDateValues(deliveryDate.date);
              const isCurrentDay = isToday(deliveryDate.date);

              return (
                <div key={`delivery-date-${deliveryDate.dayOfWeek}`}>
                  {
                    isCurrentDay &&
                    <div className={styles.currentDayWrapper}>
                      <span className={styles.currentDay}>Current Day</span>
                    </div>
                  }
                  <div>
                    <div>
                      <h3>{deliveryDate.dayOfWeek} - {date}{suffix}</h3>
                    </div>
                    <div className={styles.deliveriesWrapper}>
                      <div className={styles.deliveries}>
                        {
                          deliveries.length === 0 && <h4>No upcoming deliveries scheduled</h4>
                        }
                        {
                          deliveries.map((delivery) => {
                            const {
                              trackingId,
                              estimatedDeliveryDate,
                              actualDeliveryDate,
                              outForDelivery,
                              delivered,
                              ordersOnDelivery,
                              deliveryWindow,
                            } = delivery;
                            const { time } = FormatDateHelper.getDateValues(estimatedDeliveryDate);
                            const actualDeliveryDateValue = FormatDateHelper.getDateValues(actualDeliveryDate);

                            return (
                              <div key={`delivery-${trackingId}`} className={styles.deliveryDetail}>
                                <DeliveryWindow deliveryWindow={deliveryWindow} />
                                <div>
                                  {delivered ? <Truck /> : outForDelivery ? <DeliveryTruck /> : <Calendar />}
                                  <h4>
                                    {
                                      delivered ?
                                        `Delivered at: ${actualDeliveryDateValue.time}` :
                                        `Estimated arrival: ${time}`
                                    }
                                  </h4>
                                </div>
                                <span className={styles.description}>
                                  <span>
                                    Contains {ordersOnDelivery} {ordersOnDelivery === 1 ? 'order' : 'orders'}
                                  </span>
                                </span>
                                <Button
                                  className={`${styles.viewMore} link`}
                                  iconPos="left"
                                  text="View More"
                                  type="button"
                                  onClick={() => trackOrder(trackingId)}
                                />
                              </div>
                            );
                          })
                        }
                      </div>
                    </div>
                  </div>
                </div>
              );
            })
          }
        </div> :
        <LoadingOrEmpty loading={loading} selectedStoreId={selectedStoreId} />
      }
    </FullPageLayout>
  );
}

const DeliveryWindow = ({ deliveryWindow }: { deliveryWindow: IDeliveryWindow }): JSX.Element => {
  if (!deliveryWindow) {
    return null;
  }

  const { status, startDate, endDate } = deliveryWindow;
  const startTime = formatTime(new Date(startDate));
  const endTime = formatTime(new Date(endDate));
  const style = status.toLowerCase();

  return (
    <div className={`${styles.window} ${styles[style]}`}>
      {startTime} - {endTime}
    </div>
  );
};

const LoadingOrEmpty = ({
  loading,
  selectedStoreId,
}: {loading: boolean, selectedStoreId: number}): JSX.Element => {
  if (loading || selectedStoreId === -1) {
    return <LoadingThrobber/>;
  }

  return (
    <EmptyRecord message="No deliveries can be found for the current week." />
  );
};

const mapStateToProps = (state: IStoreState) => {
  return {
    scheduledDeliveries: state.scheduledDeliveries,
    userStores: state.userStores,
    loading: state.userStores.loading || state.scheduledDeliveries.loading,
  };
};

const mapDispatchToProps = (dispatch: Dispatch<any>) => {
  return {
    getScheduledDeliveries: (storeId: number) => dispatch(getScheduledDeliveriesRequest(storeId)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Deliveries);
