import React from 'react';
import { ISearchBarProps } from './ISearchBarProps';
import styles from './SearchBar.module.scss';
import { TextInput } from '@orderly/morrisons-component-library';

function SearchBar({
  value,
  label,
  onChange,
}: ISearchBarProps): JSX.Element {
  return (
    <div className={styles.container}>
      <TextInput
        type="text"
        value={value}
        label={label}
        name={label.replace(' ', '')}
        onChange={value => onChange(value)}
      />
      <span className={`${styles.icon} icon-search`}/>
    </div>
  );
}

export default SearchBar;
